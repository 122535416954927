/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';




const Hero = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Grid>
      {/*<Grid item container alignItems={'center'} xs={12} md={6}>*/}
        <Box alignItems={'center'} >
          <Box marginBottom={2} >
            <Divider>
            <Typography
                variant="h3"
                color="text.primary"
                align={'center'}
                gutterBottom
                sx={{
                  fontWeight: 300,
                  color: '#5f615f',
                }}
            >
              Contact
            </Typography>
            </Divider>
          </Box>
          <Box>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              textAlign={'center'}
              sx={{ fontWeight: 400 }}
            >
              Feel free to contact us through the form below.
            </Typography>
          </Box>
        </Box>
      {/*</Grid>*/}
      {/*<Grid item xs={12} md={6}>*/}
      {/*  <Box*/}
      {/*    height={1}*/}
      {/*    width={1}*/}
      {/*    display={'flex'}*/}
      {/*    justifyContent={'center'}*/}
      {/*    alignItems={'center'}*/}
      {/*  >*/}
      {/*    <Box height={1} width={1} maxWidth={500}>*/}
      {/*      /!*<Box*!/*/}
      {/*      /!*  component={'img'}*!/*/}
      {/*      /!*  src={*!/*/}
      {/*      /!*    'https://assets.maccarianagency.com/svg/illustrations/drawkit-illustration6.svg'*!/*/}
      {/*      /!*  }*!/*/}
      {/*      /!*  width={1}*!/*/}
      {/*      /!*  height={1}*!/*/}
      {/*      /!*  sx={{*!/*/}
      {/*      /!*    filter:*!/*/}
      {/*      /!*      theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',*!/*/}
      {/*      /!*  }}*!/*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*</Grid>*/}
    </Grid>
  );
};

export default Hero;
