import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const mock = [
    {
        title: 'THE CHALLENGE',
        subtitle:
            'To develop assistive technology for participants and parole officers.',
    },
    {
        title: 'THE SOLUTION',
        subtitle:
            'Produce an app and website allowing participants and officers to schedule check-ins, appointments, and other necessary parole functions.',
    },
    {
        title: 'PROJECT DETAILS',
        subtitle:
            'Date : Ongoing, Client : eHawk, Categories : Web Design, Apps',
    },
];

const DetailText = (): JSX.Element => {
    return (
        <Box>
            <Box marginBottom={4}>
                <Typography
                    align={'center'}
                    color={'text.secondary'}
                    sx={{ textTransform: 'uppercase' }}
                    variant={'subtitle2'}
                    fontWeight={600}
                >
                    Details
                </Typography>
            </Box>
            <Grid container spacing={4}>
                {mock.map((item, i) => (
                    <Grid key={i} item xs={12} sm={6} md={4}>
                        <Typography variant={'h6'} fontWeight={600} gutterBottom>
                            {item.title}
                        </Typography>
                        <Typography color="text.secondary">{item.subtitle}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default DetailText;