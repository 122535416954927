import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Form from '../Form/Form';

import Container from '../../../Container';
import Socials from "../../../Socials";

const mock = [
  {
    label: 'Email',
    value: 'info@ontometrics.com',
    icon: (
      <svg
        width={20}
        height={20}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
      </svg>
    ),
  },
  {
    label: 'Address',
    value: '969 Colorado Boulevard, Suite 102, Los Angeles, CA 90041',
    icon: (
      <svg
        width={20}
        height={20}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
    {
        label: 'Hours',
        value: 'Mon - Fri : 9am - 5pm',
        icon: (
            <AccessTimeIcon />

        )
    }
];

const Contact = (): JSX.Element => {
  const theme = useTheme();

  const LeftSide = (): JSX.Element => {
    return (
      <Box>
        {/*<Box marginBottom={2} paddingTop={}>*/}
        {/*  <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>*/}
        {/*    Contact*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
        >
          {mock.map((item, i) => (
            <Box
              key={i}
              component={ListItem}
              disableGutters
              width={'auto'}
              padding={0}
            >
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={theme.palette.secondary.main}
                  width={40}
                  height={40}
                >
                  {item.icon}
                </Box>
              </Box>
              <ListItemText primary={item.label} secondary={item.value} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  const RightSide = (): JSX.Element => {
    return (
      <iframe
        width="80%"
        height="100%"
        frameBorder="0"
        title="map"
        marginHeight={0}
        marginWidth={0}
        scrolling="no"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3302.3012064871828!2d-118.18711789999995!3d34.138635300000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c3ebf5b1959f%3A0x726d67237b5e2880!2s969%20Colorado%20Blvd%2C%20Los%20Angeles%2C%20CA%2090041!5e0!3m2!1sen!2sus!4v1657303479101!5m2!1sen!2sus"
        style={{
          minHeight: 300,
          filter:
            theme.palette.mode === 'dark'
              ? 'grayscale(0.5) opacity(0.7)'
              : 'none',
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
          <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          position={'relative'}
        >
              <Box
                  sx={{
                      flex: { xs: '0 0 100%', md: '0 0 50%' },
                      position: 'relative',
                      maxWidth: { xs: '100%', md: '50%' },
                      minHeight: { xs: 300, md: 600 },
                      order: { xs: 1, md: 2 },
                      paddingTop: 5
                  }}
              >
                  <Box
                      sx={{
                          width: { xs: 1, md: '50vw' },
                          height: '100%',
                          position: 'relative',
                      }}
                  >
                      <Box
                          sx={{
                              width: '100%',
                              height: '100%',
                              overflow: 'hidden',
                          }}
                      >
                          <Box
                              sx={{
                                  overflow: 'hidden',
                                  left: '0%',
                                  width: 1,
                                  // height: 1,
                                  position: { xs: 'relative', md: 'absolute' },
                              }}
                          >
                              <RightSide />
                          </Box>
                      </Box>
                  </Box>
              </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            width={1}
            height={1}
            order={{ xs: 2, md: 1 }}
          >
            <Container>
              <LeftSide />
            </Container>
          </Box>
        </Box>
      </Container>
        <Divider />
        <Socials />
        <Divider />
    </Box>
  );
};

export default Contact;

