import React, {useState} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Divider from "@mui/material/Divider";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal} from "@mui/material";
import DminderDetail from "../../pages/dminder";
import RePathDetail from "../../pages/RePath";



type PortfolioItem = {
    image: string
    description: string
    title: string | undefined
    url: string | null
}

const mock: PortfolioItem[] = [
    {
        image: 'https://is3-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/71/9c/ac/719cac6b-cd6f-df6b-f923-a853e1979050/ad667804-2f5e-4db5-b0ec-d5fd5643510c_Apple_iPhone_11_Pro_Max_Screenshot_0.png/460x0w.webp',
        description: 'D-Minder tracks Vitamin D from the sun. The sun is by far the best source of Vitamin D, and dminder allows you to optimize levels based on your location, body type, and time of day, all while making sure you stay safe by warning you of any risk to your skin getting burned.',
        title: 'D-Minder',
        url: '/dminder',
    },
    {
        image: 'https://is5-ssl.mzstatic.com/image/thumb/Purple123/v4/40/54/10/40541026-9347-aefc-9611-ec43e39698ec/pr_source.jpg/460x0w.webp',
        description: 'RePath is utilized to electronically assist individuals through the criminal justice process. Agencies are able to choose from multiple supervision levels depending on specific client needs.',
        title: 'RePath',
        url: '/RePath'
    },
    {
        image: 'https://s3.amazonaws.com/www.ontometrics.com/assets/projects/oppmetrix-website-thumbnail.png',
        description: 'At the peak of the government\'s putting contracts and grants out for bids, as many as 5000 new opportunities show up per day. Especially small players don\'t have time to go through all of them to see if there are items they might be interested in. This is a (Bayesian) predictor that scores each opportunity for each member, making sure that time is spent on the most valuable items first.',
        title: 'OppMetrix',
        url: null
    },
    {
        image: 'https://s3.amazonaws.com/www.ontometrics.com/assets/projects/travelplanner-daily-page.png',
        description: 'Using web or the native tablet app, people could find things based on their interests and throw them into an itinerary that they could work on before taking a trip.',
        title: 'Travel Inception',
        url: null
    },
    {
        image: 'https://s3.amazonaws.com/www.ontometrics.com/assets/projects/JSONCoding-site-thumbnail.png',
        description: 'Serializing CoreData models and sending them over the wire as JSON was painstaking and the existing tools were messy, did not use the NSCoding protocol in Cocoa. This provides a JSON-based encoder and decoder so once it was plugged in, domain classes could simply implement the NSCoding protocol, never write a line of JSON, and the object graph would be written out and read back in again.',
        title: 'JSONCODING - JSON SERIALIZATION FOR OBJECTIVE C',
        url: null
    },
    {
        image: 'https://s3.amazonaws.com/www.ontometrics.com/assets/projects/payteller-android-app.png',
        description: 'A native android app to speed the process of using payment machines.',
        title: 'PayTeller Android',
        url: null
    },
    {
        image: 'https://s3.amazonaws.com/www.ontometrics.com/assets/projects/readmissions-predictor.png',
        description: 'An application that can import admissions data from any patient population and create a predictor that could tell us, on a case-by-case basis, which people who are currently being cared for are most likely to be readmissions risks.',
        title: 'Readmissions Predictor',
        url: null
    },
    {
        image: 'https://s3.amazonaws.com/www.ontometrics.com/assets/projects/keyboard-thumbnail.png',
        description: 'Implementation of a Bayes inference engine that classified typing samples by characteristics like flight time, hold time, etc. Logic was introduced that allowed us to figure out who was authenticating before the credential typing was complete so that we were not loading the sample data while the system/user were waiting for a decision.',
        title: 'Keystroke Dynamics Implementation',
        url: null
    },
    {
        image: 'https://s3.amazonaws.com/www.ontometrics.com/assets/projects/marketmetrix-website-thumbnail.png',
        description: 'A portal for the management of clients, reports, etc. System for generating books of reports from client data. Still in use.',
        title: 'Employee Satisfaction Reporting',
        url: null
    }

];

const Portfolio = (): JSX.Element => {
    const initialState = {
        description: "", onCloseModal(event: {}, reason: "backdropClick" | "escapeKeyDown"): void {
        }, openModal: false, title: ""
    }
    const [modalState, setModalState] = useState<PortfolioItemModalProps>(initialState)
    const handleCloseModal = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        setModalState(initialState)
    };

    return (
        <Box data-aos={'fade-up'}
             data-aos-offset={100}
             data-aos-duration={600}>
            <Divider>
                <Typography
                    variant="h3"
                    color="text.primary"
                    align={'center'}
                    gutterBottom
                    sx={{
                        fontWeight: 300,
                        color: '#5f615f',
                    }}
                    paddingBottom={3}
                >
                    Portfolio
                </Typography>
            </Divider>
            <Typography
                variant="h6"
                color="text.primary"
                align={'center'}
                gutterBottom
                sx={{
                    fontWeight: 300,
                    color: '#5f615f',
                }}
                paddingBottom={3}
            >
                <Typography
                    variant="h6"
                    component="p"
                    color="text.secondary"
                    sx={{ fontWeight: 400 }}
                    align={'center'}
                    paddingBottom={3}
                >
                    Ranging from health care to restaurants, here are a few of our projects.
                    Some for clients, some our own products.
                </Typography>
            </Typography>
            <Grid container spacing={4}>
                {mock.map((item: PortfolioItem, i: number) => (
                    <Grid item xs={12} sm={6} md={4} key={i}>
                        <div>
                        {item.url == null &&
                            <PortfolioItemBox item={item} hasHref={false}/>
                        }
                        {item.url &&
                            <Button onClick={() => {
                                setModalState({
                                    description: item.description,
                                    onCloseModal(event: {}, reason: "backdropClick" | "escapeKeyDown"): void {
                                        handleCloseModal(event, reason)
                                    },
                                    openModal: true,
                                    title: item.title!
                                });
                            }}>
                                <PortfolioItemBox hasHref={false} item={item}/>
                            </Button>
                        }
                        </div>

                    </Grid>
                ))}
            </Grid>
            <PortfolioItemModal
                description={modalState!.description}
                onCloseModal={modalState!.onCloseModal}
                openModal={modalState!.openModal}
                title={modalState!.title}
            />
        </Box>
    );
};

export interface PortfolioItemModalProps {
    description: string,
    onCloseModal: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void,
    openModal: boolean,
    title: string
}
const PortfolioItemModal = (props: PortfolioItemModalProps) => {
    return (
        <Dialog
            open={props.openModal}
            onClose={props.onCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>

                {props.title == "D-Minder" &&
                    <DminderDetail/>
                }
                {props.title == "RePath" &&
                    <RePathDetail/>
                }
                {(props.title != "D-Minder" && props.title != "RePath") &&
                    <DialogContentText id="alert-dialog-description">
                        {props.description}
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {props.onCloseModal}} color="primary">
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
    )
}



const PortfolioItemBox = (props : {hasHref: Boolean, item: PortfolioItem}) => {
    const theme = useTheme();
    if (props.hasHref) {
        return (
            <Box
                component={'a'}
                href={`${props.item.url}`}
                display={'block'}
                width={1}
                height={1}
                target={'_blank'}
                sx={{
                    textDecoration: 'none',
                    transition: 'all .2s ease-in-out',
                    '&:hover': {
                        transform: `translateY(-${theme.spacing(1 / 2)})`,
                    },
                }}
            >
                <Box
                    component={Card}
                    width={1}
                    height={1}
                    display={'flex'}
                    flexDirection={'column'}
                >
                    <CardMedia
                        image={props.item.image}
                        title={props.item.title}
                        sx={{
                            height: { xs: 340, md: 400 },
                            filter:
                                theme.palette.mode === 'dark'
                                    ? 'brightness(0.7)'
                                    : 'none',
                        }}
                    />
                    <Box component={CardContent}>
                        <Typography variant={'h6'} fontWeight={700} gutterBottom color={theme.palette.primary.main}>
                            {props.item.title}
                        </Typography>
                        <Typography variant={'body2'} color="text.secondary">
                            {props.item.description}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    } else {
        return (
            <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                    textDecoration: 'none',
                    transition: 'all .2s ease-in-out',
                    '&:hover': {
                        transform: `translateY(-${theme.spacing(1 / 2)})`,
                    },
                }}
            >
                <Box
                    component={Card}
                    width={1}
                    height={1}
                    display={'flex'}
                    flexDirection={'column'}
                >
                    <CardMedia
                        image={props.item.image}
                        title={props.item.title}
                        sx={{
                            height: { xs: 340, md: 400 },
                            filter:
                                theme.palette.mode === 'dark'
                                    ? 'brightness(0.7)'
                                    : 'none',
                        }}
                    />
                    <Box component={CardContent}>
                        <Typography variant={'h6'} fontWeight={700} gutterBottom>
                            {props.item.title}
                        </Typography>
                        <Typography variant={'body2'} color="text.secondary">
                            {props.item.description}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default Portfolio;
