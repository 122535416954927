import * as React from "react"
import {OntoNavbar} from "../components/OntoNavbar";
import {ContactForm} from "../components/ContactForm";
import Portfolio from "../components/Portfolio/Portfolio";
import {FindUs} from "../components/FindUs";
import Grid from '@mui/material/Grid';
import CSS from "csstype";
import ContactPage from "../components/ContactPage";
import About from "../components/About";
import Services from "../components/Services";
import Hero from "../components/Hero/Hero";
import Container from "../components/Container";
import {Divider} from "@mui/material";
import Footer from "../layouts/Main/components/Footer";
import Box from '@mui/material/Box';
import bg1 from '../../assets/bg1.png';
import Reviews from "../components/Testimonials";
import Socials from "../components/Socials";
import Work from "../components/Work";


const pageStyles: CSS.Properties = {
  "width": '100%',
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const backgroundStyle = {
  width: '100%',
  backgroundColor: 'white',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  // paddingRight: 96,
}


// markup
const IndexPage = () => {
  return (
      <>
        <Box sx={{ p: 3 }}>
          <OntoNavbar />
        </Box>
        <main style={pageStyles}>
          <title>Ontometrics</title>
            <Box sx={{background: `linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.7)), url(${bg1})`, width: "100%", height: "66vh"}}>
              <Container sx={{zIndex: "100", position:"relative"}}>
                <Hero/>
              </Container>
            </Box>
          <section id={"services"} style={backgroundStyle}>
            <Container>
              <Services />
            </Container>
          </section>
          <section id={"work"} style={backgroundStyle}>
            <Container>
              <Work/>
            </Container>
          </section>
          <section id={"portfolio"} style={backgroundStyle}>
            <Container>
              <Portfolio/>
            </Container>
          </section>
          <Box sx={{backgroundPosition: 'fixed', background: `linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.7)), url(${bg1})`, height: 325, width: "100%"}}>
          <section >
            <Reviews />
          </section>
          </Box>
          <section id={"about"} style={backgroundStyle}>
            <About />
          </section>
          <section id={"contact"} style={backgroundStyle}>
            <Container>
              <ContactPage />
            </Container>
          </section>
        </main>
        <Footer/>
      </>
  )
}

export default IndexPage
