import React from 'react';
import Slider from 'react-slick';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const mock = [
  {
    feedback:
      'This app is incredible: The utilization of BMI in the calculation of ng/ml is necessary; but so often overlooked in applications. This app has countless features—the addition of supplements is genius—and I could go on about this app being at the pinnacle of sun health.',
    name: '@Weissberg',
    title: '',
  },
  {
    feedback:
      'An excellent little app that has made a world of difference for me in understanding my Vitamin D levels and sunlight exposure. Being able to quantify the amount of Vitamin D I’m getting and better understanding the movements of the sun through the sky has made a big material difference in my life.',
    name: '@motorsportsaregood',
    title: '',
  },
  {
    feedback:
      'I’ve been using D Minder for three years. I love how much control and how many options I have to get results. It also teaches how to be sun savvy as I use it. I have never used an in-app purchase because there are so many options available I haven’t needed to.',
    name: '@HC Stevens',
    title: '',
  },
  {
    feedback: 'The Developer has a unique special way of giving us save Data that is almost like a story! Ever have one of those moments where it is hard to put into words how nice something is? Well this app is trust me.',
    name: '@RLNfirst'
  },
];

const Reviews = (): JSX.Element => {
  const theme = useTheme();

  const sliderOpts = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingTop={5} >
      <Box marginBottom={1}>
        <Box
          component={'svg'}
          width={48}
          height={48}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          color={theme.palette.primary.main}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
          />
        </Box>
      </Box>
      <Box maxWidth={800} width={1}>
        <Slider {...sliderOpts}>
          {mock.map((item, i) => (
            <Box key={i}>
              <Box
                width={1}
                height={1}
                component={Card}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                boxShadow={0}
                sx={{ bgcolor: 'transparent', backgroundImage: 'none', color: 'white' }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography align={'center'} variant={'h6'}>
                    {item.feedback}
                  </Typography>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ paddingBottom: 2 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>

                    <ListItemText
                      sx={{ margin: 0}}
                      primary={item.name}
                    />
                  </ListItem>
                </CardActions>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Reviews;
