import React from 'react';
import Stack from '@mui/material/Stack';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar, {SnackbarCloseReason} from '@mui/material/Snackbar';

import Container from '../Container';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={1} ref={ref} variant="filled" {...props} />;
});


export enum AlertType  {
  Success,
  Warning,
  Error,
  Information
}

interface SnackBarProps {
  alert: AlertType
}

const MUIStandardSnackBars = (props:SnackBarProps): JSX.Element => {
  const [open, setOpen] = React.useState(true);



  const handleClick = (): void => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClose2 = (event: Event | React.SyntheticEvent, reason: SnackbarCloseReason): void => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function returnBanner (alert: AlertType) {
    if (alert == AlertType.Success){
    return (<Alert onClose={handleClose} sx={{ width: 1 }} severity="success">Message Sent!</Alert>)
  }
    else if (alert == AlertType.Warning){
      return (<Alert onClose={handleClose} sx={{ width: 1 }} severity="warning">Message sent with warnings.</Alert>)
  }
  else if (alert == AlertType.Error){
    return (<Alert onClose={handleClose} sx={{ width: 1 }} severity="error">Unable to send message, please try again.</Alert>)
  }

else{
    return (<Alert onClose={handleClose} sx={{ width: 1 }} severity="info"> Message sent </Alert>)
  }
  }

  return (
    <Container>
      <Stack spacing={4} sx={{ width: 1 }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose2}>
          {returnBanner(props.alert)}
        </Snackbar>
      </Stack>
    </Container>
  );
};

export default MUIStandardSnackBars;
