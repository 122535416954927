/* eslint-disable react/no-unescaped-entities */
import React, {useEffect, useState} from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ComputerIcon from '@mui/icons-material/Computer';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const mock = [
  {
    title: 'We Design',
    subtitle:
        <Grid padding={2} columnSpacing={200}>
            <Typography paddingBottom={1.5} variant={'h6'} fontWeight={100}>
                Products</Typography>
          <Divider></Divider>
          <Typography paddingTop={1.5} paddingBottom={1.5} variant={'h6'} fontWeight={100}>That Do Things</Typography>
          <Divider></Divider>
          <Typography paddingTop={1.5} paddingBottom={1.5} variant={'h6'} fontWeight={100}> Intelligently</Typography>
        </Grid>,
    icon: (
      <DesignServicesIcon fontSize={'large'} />
    ),
  },
  {
    title: 'We Create',
    subtitle:
        <Grid padding={2} columnSpacing={200}>
          <Typography paddingBottom={1.5} variant={'h6'} fontWeight={100}>Web Apps</Typography>
          <Divider></Divider>
          <Typography paddingTop={1.5} paddingBottom={1.5} variant={'h6'} fontWeight={100}>Android Apps</Typography>
          <Divider></Divider>
          <Typography paddingTop={1.5} paddingBottom={1.5} variant={'h6'} fontWeight={100}>iOS Apps</Typography>
        </Grid>,
    icon: (
      <ComputerIcon fontSize={'large'}/>
    ),
  },
  {
    title: 'We Love',
    subtitle:
        <Grid  padding={2} columnSpacing={200}>
        <Typography paddingBottom={1.5} variant={'h6'} fontWeight={100}>Apps</Typography>
  <Divider></Divider>
  <Typography paddingTop={1.5} paddingBottom={1.5} variant={'h6'} fontWeight={100}>Lean Development</Typography>
          <Divider></Divider>
          <Typography paddingTop={1.5} paddingBottom={1.5} variant={'h6'} fontWeight={100}>Probabilistic Intelligence</Typography>
        </Grid>,
    icon: (
      <FavoriteBorderIcon fontSize={'large'}/>
    ),
  },
];


const Services = (): JSX.Element => {
  const theme = useTheme();

    const [windowSize, setWindowSize] = useState(true)
    const [marginSize, setMarginSize] = useState(0)
    useEffect(() => {
        window
            .matchMedia("(min-width:768px)")
            .addEventListener('change', e=> {
                setWindowSize(e.matches)
                if(windowSize) {
                    setMarginSize(35)
                } else {
                    setMarginSize(0)
                }
            })
    }, [])

  return (
    <Box>
      <Box marginBottom={4} marginLeft={marginSize} marginRight={marginSize}>

        <Box marginBottom={2}>
          <Divider>
          <Typography
            variant="h3"
            color="text.primary"
            align={'center'}
            gutterBottom
            sx={{
              fontWeight: 300,
                color: '#5f615f',
            }}
          >
            Services
          </Typography>
        </Divider>
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{ fontWeight: 400 }}
            align={'center'}
          >
              We make Websites and Mobile Apps, responsive and native.
          </Typography>
            <Typography
                variant="h6"
                color="text.secondary"
                sx={{ fontWeight: 400 }}
                align={'center'}
                marginBottom={3}
            >
                Making apps that have intelligence is our mission!
            </Typography>
            <Divider></Divider>
        </Box>
      </Box>
      <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box
                  component={Avatar}
                  width={115}
                  height={115}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={'h5'}
                  gutterBottom
                  sx={{ fontWeight: 550 }}
                  align={'center'}
                  color={'#5f615f'}
                >
                  {item.title}
                </Typography>
                <div style={{width: 300, textAlign:"center", color: theme.palette.text.secondary }} >
                  {item.subtitle}
                </div>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
