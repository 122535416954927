/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Typed from 'react-typed';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const Hero = (): JSX.Element => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <Box>
            <Box marginBottom={2}>
                <Typography
                    variant="h2"
                    color="common.white"
                    sx={{
                        fontWeight: 700,
                    }}
                >
                    Turn your ideas
                    <br />
                    into a{' '}
                    <Typography
                        color={'primary'}
                        component={'span'}
                        variant={'inherit'}
                        sx={{
                            background: `linear-gradient(180deg, transparent 100%, ${alpha(
                                theme.palette.secondary.main,
                                0.3,
                            )} 0%)`,
                        }}
                    >
                        <Typed
                            strings={['reality.', 'future.', 'success.']}
                            typeSpeed={80}
                            loop={false}
                        />
                    </Typography>
                </Typography>
            </Box>
            <Box marginBottom={3}>
                <Typography
                    variant="h5"
                    component="p"
                    color="white"
                    sx={{ fontWeight: 400 }}
                >
                    Ontometrics will make your product look modern and professional while
                    saving you precious time.
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            >
                <Button
                    component={'a'}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth={isMd ? false : true}
                    href={'#contact'}
                >
                    Contact Us
                </Button>

            </Box>
        </Box>
    );
};

export default Hero;
