import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { Link } from 'gatsby';
import mobImage from "../../../assets/mobImage.png";
import iterativeImage from "../../../assets/iterativeImage.png";
import expertImage from "../../../assets/expertImage.png";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import {linkAndPageStyle} from "../OntoNavbar";


const mock = [
    {
        title: 'Mob Programming',
        description:
            'Mob Programming is an innovative approach in which a group of developers works together on the same task. This allows for enhanced productivity, the opportunity for our team to learn from each other, and more time spent creating your projects instead of coordinating logistics. ',
        illustration:
            mobImage,
        illustrationDark:
            'https://miro.medium.com/proxy/1*VAR7uyua--zeUKdSN2X7qw.jpeg',
        link: 'https://www.agilealliance.org/glossary/mob-programming/#q=~(infinite~true~filters~(postType~(~\'page~\'post~\'aa_book~\'aa_event_session~\'aa_experience_report~\'aa_glossary~\'aa_research_paper~\'aa_video)~tags~(~\'mob*20programming))~searchTerm~\'~sort~false~sortDirection~\'asc~page~1)',
    },
    {
        title: 'Continuous Delivery',
        description:
            'Rather than having one long-awaited release, Agile provides a stream of iterative releases. This allows for increased feedback and user input at each step while also allowing earlier releases of functional versions.',
        illustration:
            iterativeImage,
        illustrationDark:
            'https://www.agilealliance.org/wp-content/uploads/2021/12/agile-101-project-management.jpg',
        link: 'https://en.wikipedia.org/wiki/Agile_software_development'
    },
    {
        label: 'Client portal access',
        title: 'Multi-Platform Experience',
        description:
            'Ontometrics can offer expertise on any project, regardless of its desired platform or included components. Members of the team have a wide variety of experiences and strengths, which has proven to be immensely valuable in the mob setting.',
        illustration:
            expertImage,
        illustrationDark:
            'https://o.remove.bg/downloads/4e69ab4e-9261-4952-912e-d63bb447e7f1/0_AFJnStRf_kVvxS0y-removebg-preview.png',
        link: '/multi-platform-experiences'
    },
];

const Work = (): JSX.Element => {
    const theme = useTheme();
    const openInNewTab = (url: string)=> {
        // 👇️ setting target to _blank with window.open
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <Box>
            <Box marginBottom={4}>
                <Divider>
                    <Typography
                        variant="h3"
                        color="text.primary"
                        align={'center'}
                        gutterBottom
                        sx={{
                            fontWeight: 300,
                            color: '#5f615f',
                        }}
                    >
                        Why Ontometrics?
                    </Typography>
                </Divider>
                <Typography
                    variant="h6"
                    component="p"
                    color="text.secondary"
                    sx={{ fontWeight: 400 }}
                    align={'center'}
                >
                    We are a small team of developers with a strong set of principles and philosophies that guide our approach.
                </Typography>
            </Box>
            <Grid container spacing={4}>
                {mock.map((item, i) => (
                    <Grid
                        data-aos="fade-up"
                        data-aos-delay={i * 100}
                        data-aos-offset={100}
                        data-aos-duration={600}
                        key={i}
                        item
                        container
                        xs={12}
                        spacing={4}
                        direction={i % 2 === 1 ? 'row-reverse' : 'row'}
                    >
                        <Grid item container alignItems={'center'} xs={12} sm={6}>
                            <Box>
                                <Typography
                                    variant={'h6'}
                                    gutterBottom
                                    sx={{ fontWeight: 700 }}
                                >
                                    {item.title}
                                </Typography>
                                <Typography color="text.secondary">
                                    {item.description}
                                </Typography>
                                {(item.link === "/multi-platform-experiences") &&
                                    <Button size={'large'}
                                            sx={{marginTop: 2}}
                                            endIcon={<ButtonArrowIcon/>}>
                                        <Link to={item.link} style={{textDecoration: "none", color: theme.palette.primary.main}}>
                                            Learn More
                                        </Link>
                                    </Button>

                                }
                                {(item.link !== "/multi-platform-experiences") &&
                                    <Button
                                        size={'large'}
                                        sx={{ marginTop: 2 }}
                                        onClick={() => {
                                            openInNewTab(item.link)
                                        }}
                                        endIcon={
                                            <ButtonArrowIcon/>
                                        }
                                    >
                                        Learn More
                                    </Button>
                                }
                            </Box>
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                            xs={12}
                            sm={6}
                        >
                            <Box
                                component={'img'}
                                src={item.illustration}
                                // src={`${
                                //     theme.palette.mode === 'light'
                                //         ? item.illustration
                                //         : item.illustrationDark
                                // }`}
                                alt={item.title}
                                width={1}
                                maxWidth={'80%'}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Typography
                variant="h5"
                component="p"
                color="text.primary"
                sx={{ fontWeight: 400 }}
                align={'center'}
                paddingTop={5}
            >
                Sound like a good fit?
            </Typography>
            <Box
                paddingTop={2}
                m={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Stack spacing={3} direction="row">
                    <Button variant="contained" color="primary" size="large" href="#contact">
                        Contact Us
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

export const ButtonArrowIcon = () => {
    return (
        <Box
            component={'svg'}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
        </Box>
    )

}

export default Work;
