import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {Container} from '@mui/material';
import ButtonGroup from "@mui/material/ButtonGroup";
import dminderlogo from "../../../assets/dminderlogo.png"


const DetailHero = (): JSX.Element => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'relative',
                backgroundColor: theme.palette.alternate.main,
                backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
                paddingTop: 5,
            }}
        >
            <Container>
                <Box>
                    <Box
                        marginBottom={{ xs: 0, sm: 4 }}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Box
                            component={'img'}
                            src={dminderlogo}
                            alt={'Dminder Logo'}
                            width={0.075}
                        />
                        <Typography
                            variant="h3"
                            paddingTop={3}
                            gutterBottom
                            align={'center'}
                            sx={{
                                fontWeight: 900,
                            }}
                        >
                            dminder
                        </Typography>
                        <Typography
                            variant="h6"
                            component="p"
                            color="text.primary"
                            align={'center'}
                            sx={{ marginBottom: 2 }}
                        >
                            D-Minder tracks Vitamin D from the sun. The sun is by far the best source of Vitamin D, and dminder allows you to optimize levels based on your location, body type, and time of day, all while making sure you stay safe by warning you of any risk to your skin getting burned.
                        </Typography>
                        <ButtonGroup variant="text" aria-label="text button group">
                            <Button
                                href="http://dminder.ontometrics.com/"
                            >
                                Website
                            </Button>
                            <Button
                            href={'https://apps.apple.com/us/app/d-minder-pro/id547102495?platform=iphone'}
                            >
                                App Store
                            </Button>
                            <Button
                                href={'https://play.google.com/store/apps/details?id=com.ontometrics.dminder&hl=en_US&gl=US'}>
                                Google Play
                            </Button>
                        </ButtonGroup>
                    </Box>
                    <Grid
                        container
                        spacing={2}
                        sx={{ display: { xs: 'none', sm: 'flex' } }}
                    >
                        <Grid
                            item
                            container
                            // justifyContent={'flex-end'}
                            // alignItems={'flex-end'}
                            xs={4}
                            sx={{
                                '& .lazy-load-image-loaded': {
                                    width: '80%',
                                    height: '90%',
                                    display: 'flex !important',
                                },
                            }}
                        >
                            <Box
                                component={LazyLoadImage}
                                height={1}
                                width={1}
                                borderRadius={2}
                                src={'https://is3-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/71/9c/ac/719cac6b-cd6f-df6b-f923-a853e1979050/ad667804-2f5e-4db5-b0ec-d5fd5643510c_Apple_iPhone_11_Pro_Max_Screenshot_0.png/460x0w.webp'}
                                alt="..."
                                effect="blur"
                                sx={{
                                    objectFit: 'cover',
                                    filter:
                                        theme.palette.mode === 'dark' ? 'brightness(0.6)' : 'none',
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            // justifyContent={'flex-end'}
                            // alignItems={'flex-end'}
                            xs={4}
                            sx={{
                                '& .lazy-load-image-loaded': {
                                    width: '80%',
                                    height: '90%',
                                    display: 'flex !important',
                                },
                            }}
                        >
                            <Box
                                component={LazyLoadImage}
                                height={1}
                                width={1}
                                borderRadius={2}
                                src={'https://is1-ssl.mzstatic.com/image/thumb/PurpleSource122/v4/10/63/3d/10633da4-1074-762b-9e6f-bca812ae98aa/f5687c00-dd25-4862-b6c9-58ce8b03c6d4_Apple_iPhone_11_Pro_Max_Screenshot_1.png/460x0w.webp'}
                                alt="..."
                                effect="blur"
                                sx={{
                                    objectFit: 'cover',
                                    filter:
                                        theme.palette.mode === 'dark' ? 'brightness(0.6)' : 'none',
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            // justifyContent={'flex-end'}
                            // alignItems={'flex-end'}
                            xs={4}
                            sx={{
                                '& .lazy-load-image-loaded': {
                                    width: '80%',
                                    height: '90%',
                                    display: 'flex !important',
                                },
                            }}
                        >
                            <Box
                                component={LazyLoadImage}
                                height={1}
                                width={1}
                                borderRadius={2}
                                src={'https://is5-ssl.mzstatic.com/image/thumb/PurpleSource122/v4/89/50/d5/8950d5ec-6aa9-10ee-6ea6-dab4f20e64d5/608f0b4b-c566-4cf9-bcd3-ed9f4008c059_Apple_iPhone_11_Pro_Max_Screenshot_2.png/460x0w.webp'}
                                alt="..."
                                effect="blur"
                                sx={{
                                    objectFit: 'cover',
                                    filter:
                                        theme.palette.mode === 'dark' ? 'brightness(0.6)' : 'none',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default DetailHero;