import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const mock = [
    {
        title: 'THE CHALLENGE',
        subtitle:
            'After much reading about Vitamin D, the idea was hatched to make an app that would help people keep on top of how much they were getting when getting safe sun exposure, and what their likely level was.',
    },
    {
        title: 'THE SOLUTION',
        subtitle:
            'Make an app that could get satellite information from the phone to figure out where on the planet the subject was and construct the solar forecast, then make it possible to track how much D (s)he was getting for each minute outside and estimate how much it would move the overall level.\n' +
            '\n' +
            'Not long after it was released, it came to the attention of the world\'s leading authority on Vitamin D, Dr. Michael Holick, and a partnership was forged.',
    },
    {
        title: 'PROJECT DETAILS',
        subtitle:
            'Date : Ongoing, Client : ontometrics, Categories : Web Design, Apps',
    },
];

const DetailText = (): JSX.Element => {
    return (
        <Box>
            <Box marginBottom={4}>
                <Typography
                    align={'center'}
                    color={'text.secondary'}
                    sx={{ textTransform: 'uppercase' }}
                    variant={'subtitle2'}
                    fontWeight={600}
                >
                    Details
                </Typography>
            </Box>
            <Grid container spacing={4}>
                {mock.map((item, i) => (
                    <Grid key={i} item xs={12} sm={6} md={4}>
                        <Typography variant={'h6'} fontWeight={600} gutterBottom>
                            {item.title}
                        </Typography>
                        <Typography color="text.secondary">{item.subtitle}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default DetailText;