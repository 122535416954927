import React from 'react';
import { useTheme } from '@mui/material/styles';
import {Box, Container as MUIContainer} from '@mui/material';

import Container from '../Container';
import { Contact, Form, Hero, Newsletter } from './components';
import Main from "../../layouts/Main";

const ContactPage = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        sx={{
          position: 'relative',
          // backgroundColor: theme.palette.alternate.main,
          // backgroundImage: `linear-gradient(120deg, ${theme.palette.alternate.dark} 0%, ${theme.palette.background.paper} 100%)`,
          // marginTop: -13,
          // paddingTop: 13,
        }}
        data-aos={'fade-up'}
        data-aos-offset={100}
        data-aos-duration={600}
      >
        <MUIContainer>
          <Hero />
        </MUIContainer>
      </Box>
      <Box bgcolor={'alternate.main'}>
        <Container>
          <Form />
        </Container>
      </Box>
        <Contact />
      {/*<Container>*/}
      {/*  <Newsletter />*/}
      {/*</Container>*/}
    </Main>
  );
};

export default ContactPage;
