import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Container } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';


const About = (): JSX.Element => {
  const [windowSize, setWindowSize] = useState(true)
  const [marginSize, setMarginSize] = useState(0)
  useEffect(() => {
    window
        .matchMedia("(min-width:768px)")
        .addEventListener('change', e=> {
          setWindowSize(e.matches)
          if(windowSize) {
            setMarginSize(35)
          } else {
            setMarginSize(0)
          }
        })
  }, [])

  return (
      <Container>
      <Box
          data-aos={'fade-up'}
          data-aos-offset={100}
          data-aos-duration={600}
          marginBottom={4}
          marginRight={marginSize} marginLeft={marginSize}
          paddingTop={10}
          sx={{}}>
        <Divider >
        <Typography
          variant="h3"
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 300,
            color: '#5f615f',
          }}
          align={'center'}
        >
          About Us
        </Typography>
        </Divider>
        <Typography
            variant="h6"
            fontWeight={200}
            color={'text.secondary'}
            data-aos={'fade-up'}
            align={'center'}
            paddingTop={2}
            sx={{}}>
          We are a small team of developers who have worked together on many projects.
        </Typography>
        <Typography
            variant="h6"
            fontWeight={200}
            color={'text.secondary'}
            data-aos={'fade-up'}
            align={'center'}
            sx={{}}>
          We are distributed across several countries, allowing around the clock progress with continuous collaboration.
        </Typography>
        <Typography
            variant="h6"
            fontWeight={200}
            color={'text.secondary'}
            data-aos={'fade-up'}
            align={'center'}
            sx={{}}>
          We do iterative development (Agile), particularly using Lean and Kanban/Scrumban. Lately, we have been using tools that keep us focused on delivering pieces in very tight cycles, reaching Continuous Delivery where we release some times many times per day.
        </Typography>
        <Typography
            variant="h6"
            fontWeight={200}
            color={'text.secondary'}
            data-aos={'fade-up'}
            align={'center'}

            paddingTop={2}
            sx={{}}>
          The role of the Customer is critical in Agile. We work closely with online chats, screenshots, and screencasts as we build.
          Lately, we've been using Material-UI to deliver beautiful-looking products from day one.
        </Typography>
      </Box>
      {/*<Box*/}
      {/*  display={'flex'}*/}
      {/*  justifyContent={'space-between'}*/}
      {/*  alignItems={{ xs: 'flex-start', sm: 'center' }}*/}
      {/*  flexDirection={{ xs: 'column', sm: 'row' }}*/}
      {/*>*/}
        <Box marginLeft={marginSize} marginRight={marginSize}>
          <Divider>
            <Box
                data-aos={'fade-up'}
                component={'img'}
                src={
                  'https://www.gravatar.com/avatar/a6d0a3771b08d19c92ca8c8edb262fb0.png'
                }
                padding={3}
                alt={'app store'}
                width={{ xs: 70, md: 100 }}
                borderRadius={50}
            />
          </Divider>
        </Box>
        <Box marginLeft={marginSize} marginRight={marginSize}>
          <Divider>
          <Typography fontWeight={700} variant="h5"
                      data-aos={'fade-up'}
                      gutterBottom
                      sx={{
                        fontWeight: 700,
                      }}
                      color={'text.secondary'}
                      align={'center'}>
            Rob Williams
          </Typography>
          </Divider>

          <Typography
              variant="h6"
              fontWeight={200}
              data-aos={'fade-up'}
              align={'center'}
              color={'text.secondary'}
              sx={{}}>
            Rob has more than 20 years of experience with 100% involvement with product development.
            </Typography>
          <Typography
              variant="h6"
              fontWeight={200}
              data-aos={'fade-up'}
              align={'center'}
              color={'text.secondary'}
              sx={{}}
          >He has worked in many entrepreneurial environments.</Typography>
          <Typography
              variant="h6"
              fontWeight={200}
              data-aos={'fade-up'}
              align={'center'}
              color={'text.secondary'}
              sx={{}}
          >Rob was also an author on Akka in Action which is introducing Akka with its Actor Programming model and its advanced support for concurrency and fault tolerance.</Typography>
        </Box>
        <div style={{
          textAlign: 'center',
          alignItems: 'center',
          paddingTop: 4,
        }}
             data-aos={'fade-up'}
        >
          <IconButton href={'https://github.com/codeslubber'}>
          <GitHubIcon fontSize={'medium'}/>
          </IconButton>
          <IconButton href={'https://www.linkedin.com/in/rob-williams-276277/'}>
            <LinkedInIcon fontSize={'medium'}/>
          </IconButton>
          <IconButton href={'https://twitter.com/codeslubber'}>
            <TwitterIcon fontSize={'medium'}/>
          </IconButton>
        </div>
      </Container>
  );
};

export default About;
